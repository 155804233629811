import * as _react2 from "react";
var _react = "default" in _react2 ? _react2.default : _react2;
import _unitConverter from "./helpers/unitConverter";
import _animation from "./helpers/animation";
var exports = {};
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
var __rest = exports && exports.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(_react);
var unitConverter_1 = _unitConverter;
var animation_1 = _animation;
var clip = (0, animation_1.createAnimation)("ClipLoader", "0% {transform: rotate(0deg) scale(1)} 50% {transform: rotate(180deg) scale(0.8)} 100% {transform: rotate(360deg) scale(1)}", "clip");
function ClipLoader(_a) {
  var _b = _a.loading,
    loading = _b === void 0 ? true : _b,
    _c = _a.color,
    color = _c === void 0 ? "#000000" : _c,
    _d = _a.speedMultiplier,
    speedMultiplier = _d === void 0 ? 1 : _d,
    _e = _a.cssOverride,
    cssOverride = _e === void 0 ? {} : _e,
    _f = _a.size,
    size = _f === void 0 ? 35 : _f,
    additionalprops = __rest(_a, ["loading", "color", "speedMultiplier", "cssOverride", "size"]);
  var style = __assign({
    background: "transparent !important",
    width: (0, unitConverter_1.cssValue)(size),
    height: (0, unitConverter_1.cssValue)(size),
    borderRadius: "100%",
    border: "2px solid",
    borderTopColor: color,
    borderBottomColor: "transparent",
    borderLeftColor: color,
    borderRightColor: color,
    display: "inline-block",
    animation: "".concat(clip, " ").concat(0.75 / speedMultiplier, "s 0s infinite linear"),
    animationFillMode: "both"
  }, cssOverride);
  if (!loading) {
    return null;
  }
  return React.createElement("span", __assign({
    style: style
  }, additionalprops));
}
exports.default = ClipLoader;
export default exports;
export const __esModule = exports.__esModule;